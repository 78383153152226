import React from 'react'

// Styles
import './QuoteCard.scss'

const QuoteCard = ({ children, year, isPreview, className = '' }) => {
  return (
    <article
      style={{ width: '100%' }}
      className={`quote has-background-white-ter is-radiusless ${className}`}
    >
      <div>
        "
        {children
          .toString()
          .replace(/"/g, "'")
          .replaceAll('\\u002c', ',')
          .replaceAll('â', '')
          .replace(//g, '')
          .replaceAll('', '')
          .replaceAll('', '')}
        "
      </div>
      <div>
        <strong>– Class of {year}</strong>
      </div>
    </article>
  )
}

export default QuoteCard
