import React, { useEffect } from 'react'
import { TableauEmbed } from '@stoddabr/react-tableau-embed-live'

const ExploreTheData = () => {
  return (
    <section style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <TableauEmbed sourceUrl="https://public.tableau.com/views/2025CollegeFreeSpeechRankingsData/2025CollegeFreeSpeechRankingsData?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link" />
    </section>
  )
}

export default ExploreTheData
