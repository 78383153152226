import React from 'react'
import InnerHTML from 'dangerously-set-html-content'

const ViewData = () => {
  const shareString = `
  <div class='tableauPlaceholder' id='viz1661806678348' style='position: relative'><noscript><a href='#'><img alt='2025 College Free Speech Rankings Data ' src='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;20&#47;2022CollegeFreeSpeechRankingsData&#47;2022CollegeFreeSpeechRankingsData&#47;1_rss.png' style='border: none' /></a></noscript><object class='tableauViz'  style='display:none;'><param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F' /> <param name='embed_code_version' value='3' /> <param name='site_root' value='' /><param name='name' value='2022CollegeFreeSpeechRankingsData&#47;2022CollegeFreeSpeechRankingsData' /><param name='tabs' value='no' /><param name='toolbar' value='yes' /><param name='static_image' value='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;20&#47;2022CollegeFreeSpeechRankingsData&#47;2022CollegeFreeSpeechRankingsData&#47;1.png' /> <param name='animate_transition' value='yes' /><param name='display_static_image' value='yes' /><param name='display_spinner' value='yes' /><param name='display_overlay' value='yes' /><param name='display_count' value='yes' /><param name='language' value='en-US' /></object></div>                <script type='text/javascript'>                    var divElement = document.getElementById('viz1661806678348');                    var vizElement = divElement.getElementsByTagName('object')[0];                    vizElement.style.width='1300px';vizElement.style.height='1127px';                    var scriptElement = document.createElement('script');                    scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';                    vizElement.parentNode.insertBefore(scriptElement, vizElement);                </script>
  `

  return (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem' }}>
      <InnerHTML html={shareString} />
    </div>
  )
}

export default ViewData
