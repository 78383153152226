import { qualtricsPrefix } from './prefix'
import axios from 'axios'

export const createNewContact = async contact => {
  try {
    let res = await axios.post(qualtricsPrefix + '/api/legacy/fire/contact', contact)
    return res.data
  } catch (err) {
    console.log(err)
    return err
  }
}
