import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faExternalLinkAlt,
  faAngleUp,
  faAngleDown,
  faMinus,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import _ from 'lodash'
import tooltips from '../../Data/tooltips'
import Select from 'react-select'
import InnerHTML from 'dangerously-set-html-content'
import numberFormat from '../../Utility/numberFormat'

// Animation
import { motion } from 'framer-motion'

// Styles
import './Table.scss'

// Components
import QuoteCard from '../QuoteCard/QuoteCard'
import { loader } from '../TextLoaders/TextLoaders'

const Table = ({
  schools,
  warningSchools,
  setWarningSchools,
  setSchools,
  dataColumns,
  moreSchools,
  currentlySortingBy,
  setCurrentlySortedBy,
  loading,
}) => {
  // Sticky
  // Sticky element is the header columns sticking to the top.
  const [isSticky, setSticky] = useState(false)
  const stickyElement = useRef(null)
  const handleScroll = () => {
    if (stickyElement.current) {
      setSticky(stickyElement.current.getBoundingClientRect().top <= 0)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  const [tooltipHover, setTooltipHover] = useState(false)

  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const handleSort = text => {
    let array = text.split('-')
    let direction = array[1] === 'high' ? 'desc' : 'asc'
    setCurrentlySortedBy({ value: array[0], direction })
    console.log(currentlySortingBy)
  }

  const sortOptions = [
    {
      value: 'rank-low-high',
      label: 'Overall Rank (First to Last)',
    },
    {
      value: 'rank-high-low',
      label: 'Overall Rank (Last to First)',
    },
    
    {
      value: 'alphabetical-low-high',
      label: 'Alphabetical (A - Z)',
    },
    {
      value: 'alphabetical-high-low',
      label: 'Alphabetical (Z - A)',
    },
    {
      value: 'selfCensorshipRank-high-low',
      label: 'Self Censorship (Last to First)',
    },
    {
      value: 'selfCensorshipRank-low-high',
      label: 'Self Censorship (First to Last)',
    },
    {
      value: 'comfortRank-high-low',
      label: 'Comfort Expressing (Last to First)',
    },
    {
      value: 'comfortRank-low-high',
      label: 'Comfort Expressing (First to Last)',
    },
    {
      value: 'toleranceLiberalsRank-high-low',
      label: 'Tolerance for Lib. Speakers (Last to First)',
    },
    {
      value: 'toleranceLiberalsRank-low-high',
      label: 'Tolerance for Lib. Speakers (First to Last)',
    },
    {
      value: 'toleranceConservativesRank-high-low',
      label: 'Tolerance for Cons. Speakers (Last to First)',
    },
    {
      value: 'toleranceConservativesRank-low-high',
      label: 'Tolerance for Cons. Speakers (First to Last)',
    },
    {
      value: 'toleranceDifferenceRank-high-low',
      label: 'Tolerance Difference: (Last to First)',
    },
    {
      value: 'toleranceDifferenceRank-low-high',
      label: 'Tolerance Difference: (First to Last)',
    },
    {
      value: 'disruptiveConductRank-high-low',
      label: 'Disruptive Conduct (Last to First)',
    },
    {
      value: 'disruptiveConductRank-low-high',
      label: 'Disruptive Conduct (First to Last)',
    },
    {
      value: 'adminSupportRank-high-low',
      label: 'Administrative Support (Last to First)',
    },
    {
      value: 'adminSupportRank-low-high',
      label: 'Administrative Support (First to Last)',
    },
    {
      value: 'opennessRank-high-low',
      label: 'Openness (Last to First)',
    },
    {
      value: 'opennessRank-low-high',
      label: 'Openness (First to Last)',
    },
  ]

  return (
    <div className="mt-2 px-4" style={{ position: 'relative' }}>
      <div className="is-flex mb-3 rankings-list" style={{ alignItems: 'center' }}>
        <div className="ranking-item has-text-weight-light rank-column-item">Sort By</div>
        <div
          id="select-container-adjustment"
          className="rank-column-item"
          style={{ width: 350, zIndex: tooltipHover ? 1 : 300 }}
        >
          <Select
            defaultValue={{
              value: 'rank-low-high',
              label: 'Overall Rank (First to Last)',
            }}
            options={sortOptions}
            className="ml-3"
            onChange={(e, d) => {
              handleSort(e.value)
            }}
          />
        </div>
        <div className="button is-primary is-light ml-4 rank-column-item">
          <a href="#warning-schools">
            Warning Schools <FontAwesomeIcon className="ml-3 is-size-7" icon={faExternalLinkAlt} />
          </a>
        </div>
        <div style={{ flex: 1, justifyContent: 'flex-end' }} className="is-flex rank-column-item">
          <p className="has-text-grey is-size-7">
            {schools.length} Ranked Colleges - {warningSchools.length} Warning Colleges
          </p>
        </div>
      </div>

      <div
        ref={stickyElement}
        className={`guidelines columns is-hidden-touch ${isSticky ? 'is-shadow' : ''}`}
        style={{
          width: '100%',
          margin: 0,
          position: 'sticky',
          top: -2,
          backgroundColor: 'white',
          zIndex: 10,
        }}
      >
        <div className="is-center column has-text-centered" style={{ maxWidth: 65 }}>
          <span
            data-tooltip={tooltips['rank']}
            className={`is-underlined has-tooltip-multiline has-tooltip-arrow is-pointer ${
              isSticky ? 'has-tooltip-bottom' : 'has-tooltip-top'
            }`}
            onMouseEnter={() => setTooltipHover(true)}
            onMouseLeave={() => setTooltipHover(false)}
          >
            Rank
          </span>
        </div>

        <div
          className={`is-center-vertical column flex-align-left ${
            currentlySortingBy.value !== 'overall' &&
            currentlySortingBy.value !== 'rank' &&
            currentlySortingBy.value !== 'speechClimate'
              ? 'is-5'
              : 'is-6'
          }`}
        >
          School
        </div>

        {dataColumns &&
          dataColumns.map((column, i) => (
            <div key={column} className={'is-center column has-text-centered'}>
              <span
                onMouseEnter={() => setTooltipHover(true)}
                onMouseLeave={() => setTooltipHover(false)}
                data-tooltip={tooltips[column]}
                className={` is-underlined has-tooltip-multiline has-tooltip-arrow ${
                  isSticky ? 'has-tooltip-bottom' : 'has-tooltip-top'
                } ${column === 'overall' ? 'has-text-weight-bold' : ''} ${
                  column === 'score' ? 'ideoScore' : ''
                } `}
              >
                <InnerHTML
                  html={
                    column.includes('tolerance')
                      ? _.startCase(
                          column
                            .replace('Liberals', 'LiberalSpeakers')
                            .replace('Conservatives', 'ConservativeSpeakers')
                        )
                          .replace(
                            'Tolerance Gap Liberal Conservative',
                            'Tolerance Gap: <br/>Lib. => Cons.'
                          )
                          .replace(
                            'Tolerance Gap Conservative Liberal',
                            'Tolerance Gap: <br/>Cons. => Lib.'
                          )
                      : _.startCase(column)
                  }
                />
              </span>
            </div>
          ))}
        <div
          aria-label="More Details"
          className="is-center"
          style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem', width: 40 }}
        ></div>
      </div>
      <motion.div>
        <InfiniteScroll
          pageStart={0}
          loadMore={moreSchools}
          initialLoad={true}
          hasMore={loading}
          loader={<loader.p width="150%" lineHeight={'50px'} lines={4} className="is-radiusless" />}
        >
          {!_.isEmpty(schools) &&
            !loading &&
            schools.map((school, schoolIndex) => (
              <div
                key={schoolIndex}
                className="card mb-2 is-rounded is-radiusless is-relative is-school-card"
              >
                <div
                  className="card-header"
                  style={{ margin: 0, padding: 0 }}
                  onClick={() => {
                    let index = schools.indexOf(school)
                    setSchools(prevState => [
                      ...prevState.slice(0, index),
                      {
                        ...prevState[index],
                        open: !prevState[index].open,
                      },
                      ...prevState.slice(index + 1),
                    ])
                  }}
                >
                  <div
                    className="columns is-center is-multiline"
                    style={{ width: '100%', margin: 0 }}
                  >
                    {school.scores && currentlySortingBy.value !== 'rank' && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '1.5rem',
                          left: -40,
                          opacity: 0.7,
                          fontWeight: 900,
                          fontSize: '0.8rem',
                          width: '2rem',
                          textAlign: 'right',
                        }}
                      >
                        {schoolIndex + 1}:{' '}
                      </div>
                    )}

                    <div className="is-center column is-one-third-mobile rank-column is-one-fifth-mobile">
                      {school.scores.rank ? (
                        <>
                          #{school.scores.rank}
                          {school.scores.rankDifference ? (
                            <span className={'ml-1 mb-1'}>
                              {school.scores.rankDifference >= 1 && (
                                <FontAwesomeIcon
                                  size="xs"
                                  style={{ color: '#18af90' }}
                                  icon={faAngleUp}
                                />
                              )}
                              {school.scores.rankDifference <= -1 && (
                                <FontAwesomeIcon
                                  size="xs"
                                  style={{ color: 'rgb(220, 39, 40)' }}
                                  icon={faAngleDown}
                                />
                              )}
                              {/* {school.scores.rankDifference === 0 && (
                                <FontAwesomeIcon size="xs" icon={faMinus} />
                              )} */}
                            </span>
                          ) : (
                            <></>
                            // <span className={'ml-1 mb-1'}>
                            //   <FontAwesomeIcon size="xs" icon={faMinus} />
                            // </span>
                          )}
                        </>
                      ) : (
                        <span
                          className="has-tooltip-multiline has-tooltip-arrow has-tooltip-top warning-logo-span"
                          data-tooltip="A Warning label rating is assigned by FIRE to a private college or university when its policies clearly and consistently state that it prioritizes other values over a commitment to freedom of speech."
                        >
                          <img
                            style={{ width: '1.2rem', borderBottom: '1px solid black' }}
                            className="warning-logo"
                            alt="Warning Logo"
                            src={'/assets/caution.svg'}
                          />
                        </span>
                      )}
                    </div>

                    <div
                      className={`column school-column is-three-fifths-mobile ${
                        currentlySortingBy.value !== 'overall' &&
                        currentlySortingBy.value !== 'rank' &&
                        currentlySortingBy.value !== 'speechClimate'
                          ? 'is-5'
                          : 'is-6'
                      }`}
                    >
                      <Link to={`/rank/school/${school.slug}`}>
                        <span className="has-underline">{school.institutionName}</span>
                        <div className=" has-text-weight-light is-size-7 remove-underline">
                          {school.metadata.publicOrPrivate}
                        </div>
                      </Link>
                    </div>
                    {dataColumns &&
                      dataColumns.map((column, i) => (
                        <div
                          key={i}
                          className={`column stat-column has-text-centered is-centered ${
                            column === 'overall' ? 'has-text-weight-bold' : ''
                          }
                            ${
                              column === 'speechCode'
                                ? `speechCode color-${school.scores[column]}`
                                : ''
                            }
                            
                            
                        ${column === 'score' ? 'ideoScore' : ''}
                          `}
                        >
                          <span className="stats-inline-label">{_.startCase(column)}</span>
                          {column === 'speechClimate' ? (
                            <>
                              {typeof school.scores[column] === 'number'
                                ? column === 'overall'
                                  ? school.scores[column].toFixed(2)
                                  : numberFormat(school.scores[column])
                                : column.includes('Gap')
                                ? school.scores[column]
                                : _.startCase(school.scores[column])}
                              {typeof school.scores[column] === 'string' &&
                              school.scores[column].includes('avg')
                                ? '.'
                                : ''}
                            </>
                          ) : (
                            <div>
                              {typeof school.scores[column] === 'number'
                                ? column === 'overall'
                                  ? school.scores[column].toFixed(2)
                                  : numberFormat(school.scores[column])
                                : column.includes('Gap')
                                ? school.scores[column]
                                : _.startCase(school.scores[column])}
                              {typeof school.scores[column] === 'string' &&
                              school.scores[column].includes('avg')
                                ? '.'
                                : ''}
                            </div>
                          )}
                        </div>
                      ))}
                    <div
                      aria-label="More Details"
                      className="is-center more-details"
                      style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
                    >
                      <motion.div
                        variants={{
                          open: { rotate: 90 },
                          collapsed: { rotate: -90 },
                        }}
                        animate={school.open ? 'open' : 'collapsed'}
                        className="icon"
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </motion.div>
                    </div>
                  </div>
                </div>

                <Link
                  className="button is-success mobile-school-detail-button is-light"
                  to={`/rank/school/${school.slug}`}
                >
                  View School Details{' '}
                  <FontAwesomeIcon className="ml-3 is-size-7" icon={faExternalLinkAlt} />
                </Link>
                {school.open && (
                  <motion.div
                    variants={{
                      open: {
                        height: 'auto',
                        pointerEvents: 'auto',
                        transition: { duration: 0.1 },
                        overflow: 'visible',
                      },
                      collapsed: {
                        height: 0,
                        pointerEvents: 'none',
                        transition: { duration: 0.1 },
                        overflow: 'hidden',
                      },
                    }}
                    animate={school.open ? 'open' : 'collapsed'}
                    key={school.name}
                    initial="collapsed"
                    className="collapsed-school-details"
                  >
                    <motion.div
                      variants={{
                        open: { opacity: 1, y: 0, transition: { duration: 0.2, delay: 0.05 } },
                        collapsed: {
                          opacity: 0,
                          y: -10,
                          transition: { duration: 0.2, delay: 0.05 },
                        },
                      }}
                      animate="open"
                      exit="collapsed"
                      className="card-open-content"
                    >
                      <div className="columns is-multiline">
                        <div className="column is-one-third">
                          <>
                            <span
                              data-tooltip={tooltips['comfortRank']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                            >
                              Comfort Expressing Ideas
                            </span>
                            <br />
                            {numberFormat(school.scores.comfortRank.toFixed(0))}
                          </>
                        </div>

                        <div className="column is-one-third">
                          <>
                            <span
                              data-tooltip={tooltips['toleranceRank']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                            >
                              Tolerance for Speakers
                            </span>
                            <br />
                            {numberFormat(school.scores.meanToleranceRank)}
                          </>
                        </div>
                        <div className="column is-one-third">
                          <>
                            <span
                              data-tooltip={tooltips['disruptiveConductRank']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                            >
                              Disruptive Conduct
                            </span>
                            <br />
                            {numberFormat(school.scores.disruptiveConductRank.toFixed(0))}
                          </>
                        </div>
                        <div className="column is-one-third">
                          <>
                            <span
                              data-tooltip={tooltips['adminSupportRank']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                            >
                              Admin Support
                            </span>
                            <br />
                            {numberFormat(school.scores.adminSupportRank.toFixed(0))}
                          </>
                        </div>
                        <div className="column is-one-third">
                          <>
                            <span
                              data-tooltip={tooltips['opennessRank']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                            >
                              Openness
                            </span>
                            <br />
                            {numberFormat(school.scores.opennessRank.toFixed(0))}
                          </>
                        </div>
                        <div className="column is-one-third">
                          <>
                            <span
                              data-tooltip={tooltips['selfCensorship']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                            >
                              Self Censorship
                            </span>
                            <br />
                            {numberFormat(school.scores.selfCensorshipRank.toFixed(0))}
                          </>
                        </div>
                      </div>

                      <div className="divider" />

                      <div className="columns is-multiline">
                        <div className="column is-one-fifth">
                          <p>
                            <span
                              data-tooltip={tooltips['deplatformings']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                            >
                              Deplatformings
                            </span>
                          </p>
                          <div>
                            <p>{school.scores.deplatformings}</p>
                          </div>
                        </div>
                        <div className="column is-one-fifth">
                          <p>
                            <span
                              data-tooltip={tooltips['attemptedDisruptions']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                            >
                              Disruptions
                            </span>
                          </p>
                          <div>
                            <p>{school.scores.attemptedDisruptions}</p>
                          </div>
                        </div>
                        <div className="column is-one-fifth">
                          <p>
                            <span
                              data-tooltip={tooltips['sanctionedScholars']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                            >
                              Sanctioned Scholars
                            </span>
                          </p>
                          <div>
                            <p>{school.scores.sanctionedScholars}</p>
                          </div>
                        </div>
                        <div className="column is-one-fifth">
                          <p>
                            <span
                              data-tooltip={tooltips['sanctionedStudents']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                            >
                              Sanctioned Students
                            </span>
                          </p>
                          <div>
                            <p>{school.scores.sanctionedStudents}</p>
                          </div>
                        </div>
                        <div className="column is-one-fifth">
                          <p>
                            <span
                              data-tooltip={tooltips['honorRollStatements']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                            >
                              Honor Roll Statements
                            </span>
                          </p>
                          <div>
                            <p>{school.scores.honorRollStatements}</p>
                          </div>
                        </div>
                      </div>

                      <div className="divider" />

                      <div className="columns my-0">
                        {school && school.quotes && school.quotes[0] && (
                          <div className="column is-flex">
                            <QuoteCard
                              isPreview={true}
                              year={school.quotes[0].year}
                              className="mb-0"
                            >
                              {school.quotes[0].text}
                            </QuoteCard>
                          </div>
                        )}

                        {school && school.quotes && school.quotes[1] && (
                          <div className="column is-flex">
                            <QuoteCard
                              isPreview={true}
                              year={school.quotes[1].year}
                              className="mb-0"
                            >
                              {school.quotes[1].text}
                            </QuoteCard>
                          </div>
                        )}
                      </div>

                      <div className="is-flex circle-columns my-3 mb-5">
                        {school.viewpoint && (
                          <div className="circle-column">
                            <div className="level is-relative content">
                              <h5 className="">Majority Viewpoint</h5>
                              <p>{school.viewpoint}</p>
                            </div>
                          </div>
                        )}

                        {school.viewpointRatio && (
                          <div className="circle-column">
                            <div className="level is-relative content">
                              <h5 className="">Viewpoint Ratio</h5>
                              <p>{school.viewpointRatio}</p>
                            </div>
                          </div>
                        )}

                        {school.metadata.admissionsRate && (
                          <div className="circle-column">
                            <div className="level is-relative content">
                              <h5>Admission Rate</h5>
                              <p>{parseInt(school.metadata.admissionsRate * 100)}%</p>
                            </div>
                          </div>
                        )}

                        {school.metadata.totalUndergradEnrollment && (
                          <div className="circle-column">
                            <div className="level is-relative content">
                              <h5>Undergrad Enrollment</h5>
                              <p>{formatNumber(school.metadata.totalUndergradEnrollment)}</p>
                            </div>
                          </div>
                        )}

                        {/* data-tooltip={tooltips['speakers']}
                              className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                             */}

                        {school.metadata.outOfStateTuition && (
                          <div className="circle-column">
                            <div className="level is-relative content">
                              <h5
                                data-tooltip={tooltips['tuition']}
                                className="is-underlined has-tooltip-multiline has-tooltip-arrow"
                              >
                                Tuition
                              </h5>
                              <p>${formatNumber(school.metadata.outOfStateTuition)}</p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="divider" />

                      <div className="level">
                        <div className="level-left">
                          <div>
                            <>
                              <span
                                data-tooltip={tooltips['speechCode']}
                                className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                              >
                                Speech Code
                              </span>
                              <br />
                              <div
                                className={`speechCode color-${school.scores.speechCode}`}
                                style={{
                                  display: 'flex',
                                  flex: undefined,
                                  marginBottom: 0,
                                  width: '10rem',
                                  marginLeft: 0,
                                }}
                              >
                                {_.startCase(school.scores.speechCode)}
                              </div>
                            </>
                          </div>
                          {/* {school.metadata.responseCount && (
                            <div className="circle-column pt-4">
                              <div className="level is-relative content">
                                <h5
                                  data-tooltip={tooltips['responseCount']}
                                  className="is-underlined has-tooltip-multiline has-tooltip-arrow"
                                >
                                  Survey Respondents
                                </h5>
                                <p>{school.metadata.responseCount.toLocaleString()}</p>
                              </div>
                            </div>
                          )} */}
                        </div>
                        <div className="level-right">
                          <Link
                            className="button is-success mt-3"
                            to={`/rank/school/${school.slug}`}
                          >
                            View School Details{' '}
                            <FontAwesomeIcon className="ml-3 is-size-7" icon={faExternalLinkAlt} />
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  </motion.div>
                )}
              </div>
            ))}
        </InfiniteScroll>
      </motion.div>

      {!loading && warningSchools && warningSchools.length > 0 && (
        <div
          id="warning-schools"
          style={{
            marginTop: '3rem',
            backgroundColor: '#FFC3C3',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '3rem 1.5rem',
            marginLeft: '-1.5rem',
            marginRight: '-1.5rem',
          }}
        >
          <h1 style={{ fontSize: '2rem' }}>Warning Schools</h1>
          <div style={{ marginBottom: '3rem', textAlign: 'center' }}>
            The following schools have policies that clearly and consistently state that it
            prioritizes other values over a commitment to freedom of speech. These colleges were
            excluded from the rankings and were scored relative to one another.
          </div>
          <div style={{ width: '100%' }}>
            {warningSchools &&
              warningSchools.length > 0 &&
              warningSchools.map((school, schoolIndex) => {
                return (
                  <div
                    key={schoolIndex}
                    className="card mb-2 is-rounded is-radiusless is-relative is-school-card"
                  >
                    <div
                      className="card-header"
                      style={{ margin: 0, padding: 0 }}
                      onClick={() => {
                        let index = warningSchools.indexOf(school)
                        setWarningSchools(prevState => [
                          ...prevState.slice(0, index),
                          {
                            ...prevState[index],
                            open: !prevState[index].open,
                          },
                          ...prevState.slice(index + 1),
                        ])
                      }}
                    >
                      <div
                        className="columns is-center is-multiline"
                        style={{ width: '100%', margin: 0 }}
                      >
                        {school.scores && currentlySortingBy.value !== 'rank' && (
                          <div
                            style={{
                              position: 'absolute',
                              top: '1.5rem',
                              left: -40,
                              opacity: 0.7,
                              fontWeight: 900,
                              fontSize: '0.8rem',
                              width: '2rem',
                              textAlign: 'right',
                            }}
                          >
                            {schoolIndex + 1}:{' '}
                          </div>
                        )}

                        <div className="is-center column is-one-third-mobile rank-column is-one-fifth-mobile">
                          {school.scores.rank ? (
                            `#${school.scores.rank}`
                          ) : (
                            <span
                              className="has-tooltip-multiline has-tooltip-arrow has-tooltip-top warning-logo-span"
                              data-tooltip="A Warning label rating is assigned by FIRE to a private college or university when its policies clearly and consistently state that it prioritizes other values over a commitment to freedom of speech."
                            >
                              <img
                                style={{ width: '1.2rem', borderBottom: '1px solid black' }}
                                className="warning-logo"
                                alt="Warning Logo"
                                src={'/assets/caution.svg'}
                              />
                            </span>
                          )}
                        </div>
                        <div
                          className={`column school-column is-three-fifths-mobile ${
                            currentlySortingBy.value !== 'overall' &&
                            currentlySortingBy.value !== 'rank' &&
                            currentlySortingBy.value !== 'speechClimate'
                              ? 'is-5'
                              : 'is-6'
                          }`}
                        >
                          <Link to={`/rank/school/${school.slug}`}>
                            <span className="has-underline">{school.institutionName}</span>
                            <div className=" has-text-weight-light is-size-7 remove-underline">
                              {school.metadata.publicOrPrivate}
                            </div>
                          </Link>
                        </div>
                        {dataColumns &&
                          dataColumns.map((column, i) => (
                            <div
                              key={i}
                              className={`column stat-column has-text-centered is-centered ${
                                column === 'overall' ? 'has-text-weight-bold' : ''
                              }
                      ${column === 'speechCode' ? `speechCode color-${school.scores[column]}` : ''}
                      
                  ${column === 'score' ? 'ideoScore' : ''}
                    `}
                            >
                              <span className="stats-inline-label">{_.startCase(column)}</span>
                              {column === 'speechClimate' ? (
                                <>
                                  {typeof school.scores[column] === 'number'
                                    ? column === 'overall'
                                      ? school.scores[column].toFixed(2)
                                      : school.scores[column].toFixed(1)
                                    : column.includes('Gap')
                                    ? school.scores[column]
                                    : _.startCase(school.scores[column])}
                                  {typeof school.scores[column] === 'string' &&
                                  school.scores[column].includes('avg')
                                    ? '.'
                                    : ''}
                                </>
                              ) : (
                                <div>
                                  {typeof school.scores[column] === 'number'
                                    ? column === 'overall'
                                      ? school.scores[column].toFixed(2)
                                      : school.scores[column].toFixed(1)
                                    : column.includes('Gap')
                                    ? school.scores[column]
                                    : _.startCase(school.scores[column])}
                                  {typeof school.scores[column] === 'string' &&
                                  school.scores[column].includes('avg')
                                    ? '.'
                                    : ''}
                                </div>
                              )}
                            </div>
                          ))}
                        <div
                          aria-label="More Details"
                          className="is-center more-details"
                          style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
                        >
                          <motion.div
                            variants={{
                              open: { rotate: 90 },
                              collapsed: { rotate: -90 },
                            }}
                            animate={school.open ? 'open' : 'collapsed'}
                            className="icon"
                          >
                            <FontAwesomeIcon icon={faAngleLeft} />
                          </motion.div>
                        </div>
                      </div>
                    </div>

                    <Link
                      className="button is-success mobile-school-detail-button is-light"
                      to={`/rank/school/${school.slug}`}
                    >
                      View School Details{' '}
                      <FontAwesomeIcon className="ml-3 is-size-7" icon={faExternalLinkAlt} />
                    </Link>
                    {school.open && (
                      <motion.div
                        variants={{
                          open: {
                            height: 'auto',
                            pointerEvents: 'auto',
                            transition: { duration: 0.1 },
                            overflow: 'visible',
                          },
                          collapsed: {
                            height: 0,
                            pointerEvents: 'none',
                            transition: { duration: 0.1 },
                            overflow: 'hidden',
                          },
                        }}
                        animate={school.open ? 'open' : 'collapsed'}
                        key={school.name}
                        initial="collapsed"
                        className="collapsed-school-details"
                      >
                        <motion.div
                          variants={{
                            open: {
                              opacity: 1,
                              y: 0,
                              transition: { duration: 0.2, delay: 0.05 },
                            },
                            collapsed: {
                              opacity: 0,
                              y: -10,
                              transition: { duration: 0.2, delay: 0.05 },
                            },
                          }}
                          animate="open"
                          exit="collapsed"
                          className="card-open-content"
                        >
                          <div className="columns is-multiline">
                            <div className="column is-one-third">
                              <p>
                                <span
                                  data-tooltip={tooltips['speechCode']}
                                  className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                                >
                                  Speech Code
                                </span>
                                <br />
                                <div
                                  className={`speechCode color-${school.scores.speechCode}`}
                                  style={{
                                    display: 'flex',
                                    flex: undefined,
                                    marginBottom: 0,
                                    width: '10rem',
                                    marginLeft: 0,
                                  }}
                                >
                                  {_.startCase(school.scores.speechCode)}
                                </div>
                              </p>
                            </div>

                            <div className="column is-one-third">
                              <p>
                                <span
                                  data-tooltip={tooltips['deplatformings']}
                                  className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                                >
                                  Deplatformings
                                </span>
                                <br />
                                {school.scores.deplatformings}
                              </p>
                            </div>

                            <div className="column is-one-third">
                              <p>
                                <span
                                  data-tooltip={tooltips['attemptedDisruptions']}
                                  className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                                >
                                  Disruptions
                                </span>
                                <br />
                                {school.scores.attemptedDisruptions}
                              </p>
                            </div>

                            <div className="column is-one-third">
                              <p>
                                <span
                                  data-tooltip={tooltips['sanctionedScholars']}
                                  className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                                >
                                  Sanctioned Scholars
                                </span>
                                <br />
                                {school.scores.sanctionedScholars}
                              </p>
                            </div>

                            <div className="column is-one-third">
                              <p>
                                <span
                                  data-tooltip={tooltips['sanctionedStudents']}
                                  className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                                >
                                  Sanctioned Students
                                </span>
                                <br />
                                {school.scores.sanctionedStudents}
                              </p>
                            </div>

                            <div className="column is-one-third">
                              <p>
                                <span
                                  data-tooltip={tooltips['honorRollStatements']}
                                  className="is-underlined is-size-7 has-text-grey has-tooltip-multiline has-tooltip-arrow"
                                >
                                  Honor Roll Statements
                                </span>
                                <br />
                                {school.scores.honorRollStatements}
                              </p>
                            </div>
                          </div>

                          <div className="divider" />

                          <div className="columns my-0">
                            {school && school.quotes && school.quotes[0] && (
                              <div className="column is-flex">
                                <QuoteCard
                                  isPreview={true}
                                  year={school.quotes[0].year}
                                  className="mb-0"
                                >
                                  {school.quotes[0].text}
                                </QuoteCard>
                              </div>
                            )}

                            {school && school.quotes && school.quotes[1] && (
                              <div className="column is-flex">
                                <QuoteCard
                                  isPreview={true}
                                  year={school.quotes[1].year}
                                  className="mb-0"
                                >
                                  {school.quotes[1].text}
                                </QuoteCard>
                              </div>
                            )}
                          </div>

                          <div className="is-flex circle-columns my-3 mb-5">
                            {school.viewpoint && (
                              <div className="circle-column">
                                <div className="level is-relative content">
                                  <h5 className="">Majority Viewpoint</h5>
                                  <p>{school.viewpoint}</p>
                                </div>
                              </div>
                            )}

                            {school.viewpointRatio && (
                              <div className="circle-column">
                                <div className="level is-relative content">
                                  <h5 className="">Viewpoint Ratio</h5>
                                  <p>{school.viewpointRatio}</p>
                                </div>
                              </div>
                            )}

                            {school.metadata.admissionsRate && (
                              <div className="circle-column">
                                <div className="level is-relative content">
                                  <h5>Admission Rate</h5>
                                  <p>{parseInt(school.metadata.admissionsRate * 100)}%</p>
                                </div>
                              </div>
                            )}

                            {school.metadata.totalUndergradEnrollment && (
                              <div className="circle-column">
                                <div className="level is-relative content">
                                  <h5>Undergrad Enrollment</h5>
                                  <p>{formatNumber(school.metadata.totalUndergradEnrollment)}</p>
                                </div>
                              </div>
                            )}

                            {school.metadata.outOfStateTuition && (
                              <div className="circle-column">
                                <div className="level is-relative content">
                                  <h5>Tuition</h5>
                                  <p>${formatNumber(school.metadata.outOfStateTuition)}</p>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="divider" />

                          <div className="level">
                            <div className="level-left">
                              {school.metadata.responseCount && (
                                <div className="circle-column pt-4">
                                  <div className="level is-relative content">
                                    <h5
                                      data-tooltip={tooltips['responseCount']}
                                      className="is-underlined has-tooltip-multiline has-tooltip-arrow"
                                    >
                                      Survey Respondents
                                    </h5>
                                    <p>{school.metadata.responseCount.toLocaleString()}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="level-right">
                              <Link
                                className="button is-success mt-3"
                                to={`/rank/school/${school.slug}`}
                              >
                                View School Details{' '}
                                <FontAwesomeIcon
                                  className="ml-3 is-size-7"
                                  icon={faExternalLinkAlt}
                                />
                              </Link>
                            </div>
                          </div>
                        </motion.div>
                      </motion.div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      )}
    </div>
  )
}

export default Table
