import React from 'react'

const SliderCard = ({ children, school, year }) => {
  return (
    <div style={{ height: '100%' }} className="card has-background-white-ter mx-4 is-center">
      <div className="card-content">
        <p>"{children}"</p>
        <p className="mt-2">
          <strong>–{school}</strong>, Class of {year}
        </p>
      </div>
    </div>
  )
}

export default SliderCard
