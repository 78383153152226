import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'

// API
import { getSchool } from '../api/schools'

// Components
import SchoolOverview from '../Components/SchoolOverview/SchoolOverview'
import { loader } from '../Components/TextLoaders/TextLoaders'
import QuoteCard from '../Components/QuoteCard/QuoteCard'
import ShareComponent from '../Components/ShareComponent/ShareComponent'

// Animation
import { motion } from 'framer-motion'

const School = () => {
  let { schoolID: schoolSlug } = useParams()

  const [schoolDetails, setSchoolDetails] = useState()

  // useEffect(() => {
  //   if (schoolDetails) {
  //     // Set display to block
  //     //document.querySelector('#hs-contact-all').style.display = 'block'
  //   }
  // }, [schoolDetails])

  useEffect(() => {
    let initialData = async () => {
      let schoolData = await getSchool(schoolSlug)
      setSchoolDetails(schoolData)
    }

    initialData()
  }, [schoolSlug])

  const [loadedImage, setLoadedImage] = useState(false)

  return (
    <section className="section">
      <div className="container">
        <div className="columns main-overview-columns">
          <div className="no-mobile">
            <SchoolOverview school={schoolDetails} />
          </div>

          <motion.div
            initial={{ height: 0, display: 'none' }}
            animate={
              loadedImage && {
                height: 120,
                display: 'block',
                transition: { delay: 0.3, duration: 0.3 },
              }
            }
            className="map-header has-background-info only-mobile"
          >
            <img
              alt="School"
              className="school-photo"
              onLoad={() => setLoadedImage(true)}
              src={schoolDetails && schoolDetails.metadata.campusPhotoURL}
            />
          </motion.div>

          <div className="column ml-5 main-data-overview-column">
            <div className="level mb-3">
              <div className="level-left is-block" style={{ flex: 1 }}>
                <loader.h2 width="50%" className="title is-4 has-text-weight-bold mb-0">
                  {schoolDetails && schoolDetails.institutionName}
                </loader.h2>
              </div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={schoolDetails && { opacity: 1 }}
                className="level-right"
              >
                <ShareComponent school={schoolDetails} />
              </motion.div>
            </div>
            <div className="divider mt-0 mb-0 no-mobile" style={{ height: 2, opacity: 0.5 }} />

            <div className="is-block mt-5">
              <loader.h2 className="subtitle is-5 has-text-weight-bold" width="20%">
                {schoolDetails && 'Highlights'}
              </loader.h2>
              <div className="content">
                <loader.p width="75%" lines={6}>
                  {schoolDetails && schoolDetails.highlights && (
                    <ul>
                      {schoolDetails.highlights.map((highlight, i) => (
                        <li key={i}>{highlight}</li>
                      ))}
                    </ul>
                  )}
                </loader.p>
                <motion.a
                  initial={{ opacity: 0 }}
                  animate={schoolDetails && { opacity: 1 }}
                  className="button is-success mt-4 no-mobile"
                  href={schoolDetails && '/rank/explore/'}
                >
                  Explore School Data
                </motion.a>

                <motion.a
                  initial={{ opacity: 0 }}
                  animate={schoolDetails && { opacity: 1 }}
                  className="button is-success is-light mt-4 compare-button-detail"
                >
                  <Link style={{ color: '#17a689' }} to={`/rank/compare?schools=["${schoolSlug}"]`}>
                    Compare {(schoolDetails && schoolDetails.institutionName) || 'School'}
                  </Link>
                </motion.a>
              </div>
            </div>

            <div className="only-mobile is-fullwidth">
              <SchoolOverview school={schoolDetails} />
            </div>

            <div className="is-block mt-6">
              <loader.h2 className="subtitle is-5 has-text-weight-bold mb-2" width="20%">
                {schoolDetails && 'Student Voices'}
              </loader.h2>
              <loader.p className="mb-5 pb-2 has-text-weight-light" width="100%">
                {schoolDetails && (
                  <em>
                    Please share a moment where you personally felt you could not express your
                    opinion on your campus.
                  </em>
                )}
              </loader.p>
              <div className="content">
                <loader.p width="200%" lineHeight={'130px'} lines={4}>
                  {schoolDetails && schoolDetails.quotes
                    ? schoolDetails.quotes.map((quote, i) => (
                        <QuoteCard key={i} type={quote.category} year={quote.year}>
                          {quote.text}
                        </QuoteCard>
                      ))
                    : schoolDetails && 'No quotes found for this school.'}
                </loader.p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default School
