/* eslint-disable quotes */

const topQuotes = [
  {
    school: 'American University',
    quote:
      'Conservatives are slammed on this campus for simply just having opposing views. Disgraceful. A professor of mine nearly got fired for just expressing how he felt about topics but wasn’t being offensive',
    graduationYear: '2026',
  },
  {
    school: 'Davidson College',
    quote:
      'An impromptu event where we would read off the names of Palestinians killed in Gaza was rebuked by the school, with the president threatening to make disciplinary action against those students whose names were recorded.',
    graduationYear: '2024',
  },
  {
    school: 'Grinnell College',
    quote:
      'One of my friends was canceled for opening a free speech club and having to hear my other friend group making fun and insulting my other friends was a bit tough.',
    graduationYear: '2026',
  },
  {
    school: 'Illinois State University',
    quote:
      'I am both a Lutheran and strong Democrat, and sometimes my opinions as a democrat are different than most Christians, and vice-versa. So I have trouble with some students because I don’t feel comfortable talking about my views when they conflict with many Christians, even though I wish I could state my opinion.',
    graduationYear: '2027',
  },
  {
    school: 'Stevens Institute of Technology',
    quote:
      'In a class discussion about how we remember the past, specifically how we remember racism, the professor and various students were essentially painting all conservatives as evil. I am not a conservative, but I think it is unfair to paint conservatives as evil. I did not feel comfortable speaking up because I did not want the professor to think that I am evil.',
    graduationYear: '2027',
  },
]

export default topQuotes
