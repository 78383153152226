import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share'
import ReactGA from 'react-ga'

const ShareComponent = ({ school, position }) => {
  return (
    <div
      className="is-center-vertical"
      style={{ justifyContent: position && position === 'left' ? 'flex-start' : 'flex-end' }}
    >
      <p style={{ opacity: 0.5, marginTop: -5 }} className="mr-2">
        Share
      </p>

      <FacebookShareButton
        quote={
          school
            ? `${school.institutionName} ranked #${school.scores.overall.rank} on the #2025CollegeFreeSpeech Rankings. Find out how your university scored!`
            : 'The #2025CollegeFreeSpeech Rankings are out! Find out how schools across the country score on their free speech climates!'
        }
        className="ml-2"
        url={window.location.href}
        onClick={() => {
          ReactGA.event({
            category: 'Click',
            action: 'Clicked "Facebook Share Button"',
            label: 'Click: Facebook share button',
          })
        }}
      >
        <FacebookIcon size="30" round />
      </FacebookShareButton>
      <TwitterShareButton
        className="ml-2"
        url={window.location.href}
        onClick={() => {
          ReactGA.event({
            category: 'Click',
            action: 'Clicked "Twitter Share Button"',
            label: 'Click: Twitter share button',
          })
        }}
        title={
          school
            ? `${school.institutionName} ranked #${school.scores.overall.rank} on the #2025CollegeFreeSpeech Rankings. Find out how your university scored!`
            : 'The #2025CollegeFreeSpeech Rankings are out! Find out how schools across the country score on their free speech climates!'
        }
      >
        <TwitterIcon size="30" round />
      </TwitterShareButton>
      <LinkedinShareButton
        onClick={() => {
          ReactGA.event({
            category: 'Click',
            action: 'Clicked "Linkedin Share Button"',
            label: 'Click: Linkedin share button',
          })
        }}
        className="ml-2"
        url={window.location.href}
      >
        <LinkedinIcon size="30" round />
      </LinkedinShareButton>
    </div>
  )
}

export default ShareComponent
