import axios from 'axios'
import prefix from './prefix'
import _ from 'lodash'

const YEAR = 2024

/* TODO: Move this to a helpers function */
export const formatFilters = filters =>
  filters.reduce((acc, filter) => {
    const existingIndex = acc.findIndex(x => x[0] === filter[0])
    if (existingIndex > -1) {
      return [
        ...acc.slice(0, existingIndex),
        _.uniq(acc[existingIndex].concat(filter.slice(1))),
        ...acc.slice(existingIndex + 1),
      ]
    } else {
      return [...acc, filter]
    }
  }, [])

export const getSchool = name => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let res = await axios.get(prefix + `/api/public/speech/one/${name}/${YEAR}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'ngrok-skip-browser-warning': 'true',
        },
      })

      res.data.scores.speechCode = res.data.scores.speechCode.toLowerCase()
      res.data.speechCode = res.data.scores.speechCode.toLowerCase()

      if (res.data.scores.overall.overall < 0) {
        res.data.scores.overall.overall = 0.0
      }

      resolve(res.data)
    } catch (err) {
      console.log(err)
      reject(err)
    }
  })
  return promise
}

export const getCompareSchools = search => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.get(prefix + '/api/public/speech/schools/overall', {
        params: {
          search: search,
          filters: [],
          pageSize: 100,
          page: 0,
          year: YEAR,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'ngrok-skip-browser-warning': 'true',
        },
      })

      resolve(res.data)
    } catch (err) {
      /* TODO: Handle errors better and report them to mixpanel */

      console.dir({
        error: err,
        search: search,
      })

      reject(err.message)
    }
  })
}

/* TODO: Refactor the getAll to be getAll */
export const getAll = (filters, search, page, sorting, columnSorting) => {
  if (filters?.find(x => x[0] === 'conference')) {
    let idx = filters.indexOf(filters?.find(x => x[0] === 'conference'))
    if (filters[idx].find(y => y === 'Liberal Arts')) {
      if (filters[idx].length < 3) {
        filters.splice(idx, 1)
      } else {
        filters[idx] = filters[idx].filter(z => z !== 'Liberal Arts')
      }
      filters.push(['isLiberalArtsCollege', true])
    }
  }
  //console.log(filters)

  return new Promise(async (resolve, reject) => {
    try {
      /* TODO: Make the schools dimension dynamic based on sort variable */
      let res = await axios.get(prefix + `/api/public/speech/schools/${sorting}`, {
        params: {
          filters: filters ? JSON.stringify(formatFilters(filters)) : null,
          search: search,
          pageSize: 260,
          page: page - 1,
          sortBy: columnSorting.value,
          sortOrder: columnSorting.direction,
          year: YEAR,
        },
        // ngrok ngrok-skip-browser-warning
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'ngrok-skip-browser-warning': 'true',
        },
      })

      res.data.forEach((d, idx) => {
        // Temp quotes
        if (!res.data[idx].quotes || res.data[idx].quotes.length === 0) {
          res.data[idx].quotes = [
            {
              _id: Math.floor(Math.random() * 100000000),
              year: '2024',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id vestibulum erat. Vivamus tincidunt, magna et lacinia tristique, quam nisi luctus neque, a viverra purus nisl vel purus. Nunc blandit nisl massa, auctor tincidunt nulla aliquet vitae. Quisque iaculis erat gravida cursus tincidunt. Donec eget purus vel leo auctor placerat non non metus.',
              featured: true,
              wordCount: 100,
            },
            {
              _id: Math.floor(Math.random() * 100000000),
              year: '2024',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id vestibulum erat. Vivamus tincidunt, magna et lacinia tristique, quam nisi luctus neque, a viverra purus nisl vel purus. Nunc blandit nisl massa, auctor tincidunt nulla aliquet vitae. Quisque iaculis erat gravida cursus tincidunt. Donec eget purus vel leo auctor placerat non non metus.',
              featured: true,
              wordCount: 100,
            },
          ]
        }

        console.log(res.data[idx])
        //console.log(d)
        res.data[idx].speechCode = res.data[idx].scores.speechCode.toLowerCase()
        res.data[idx].viewpoint = res.data[idx].scores.viewpoint

        if (res.data[idx].scores.overall < 0) {
          res.data[idx].scores.overall = 0.0
        }
        // Assign speech code
        res.data[idx].scores.speechCode = res.data[idx].scores.speechCode.toLowerCase()
        res.data[idx].scores.speechClimate = res.data[idx].scores.speechClimate
          .toLowerCase()
          .replace('slightly above average', 'slightly above avg.')
          .replace('slightly below average', 'slightly below avg.')
        let {
          toleranceForLiberalsRank: toleranceLiberalsRank,
          toleranceForConservativesRank: toleranceConservativesRank,
        } = res.data[idx].scores

        if (toleranceLiberalsRank === 'Warning' || toleranceConservativesRank === 'Warning') {
          res.data[idx].scores.toleranceGapLiberalConservative = 'Warning'
          res.data[idx].scores.toleranceGapConservativeLiberal = 'Warning'
        } else {
          // Assign tolerance gap numbers
          res.data[idx].scores.toleranceGapLiberalConservative = `${
            toleranceLiberalsRank?.toFixed(1) - toleranceConservativesRank?.toFixed(1) > 0
              ? '+'
              : ''
          }${(toleranceLiberalsRank?.toFixed(1) - toleranceConservativesRank?.toFixed(1))?.toFixed(
            1
          )}`
          res.data[idx].scores.toleranceGapConservativeLiberal = `${
            toleranceConservativesRank?.toFixed(1) - toleranceLiberalsRank?.toFixed(1) > 0
              ? '+'
              : ''
          }${(toleranceConservativesRank?.toFixed(1) - toleranceLiberalsRank?.toFixed(1))?.toFixed(
            1
          )}`
        }
      })

      // Manually sort by toleranceGap
      if (columnSorting.value.includes('Gap')) {
        res.data = _.sortBy(res.data, d => +d.scores[columnSorting.value])
        if (columnSorting.direction === 'desc') {
          res.data = res.data.reverse()
        }
      }

      // Open the first page
      if (page === 1) {
        res.data[0].open = true
      }
      //res.data = res.data.slice(0, 50)

      let warningSchools = res.data.filter(d => d.scores.speechCode.toLowerCase() === 'warning')
      warningSchools = warningSchools.map(d => ({ ...d, open: false }))

      resolve({
        schools: res.data.filter(d => d.scores.speechCode.toLowerCase() !== 'warning'),
        warningSchools,
      })
    } catch (err) {
      console.log(err)
      /* TODO: Handle Errors Better */
      reject(err.message)
    }
  })
}
